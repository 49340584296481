@import '../../../assets/sass/custom';

.projects {
  position: relative
}

.projects--has-selection {
  padding-bottom: 180px
}

.projects--has-selection .project {
  color: #e3deda
}

.project {
  clear: none;
  color: $body-color;
  cursor: pointer;
  float: left;
  height: 10rem;
  padding: 28px 0 0;
  text-align: inherit;
  text-decoration: none;
  -moz-transition: color .3s;
  -o-transition: color .3s;
  -webkit-transition: color .3s;
  transition: color .3s;
}

.project.disabled {
  color: #e3deda;
  cursor: pointer
}

.project.selected {
  color: $primary !important
}

.symbol {
  font: 400 5.5rem/5rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
}

.project-data-container {
  clear: both;
  overflow: hidden;
  position: relative
}

.project-data-wrapper {
  overflow: hidden;
  position: relative
}

.project-data-wrapper--with-transition {
  -moz-transition: height .3s, -moz-transform .3s;
  -o-transition: height .3s, -o-transform .3s;
  -webkit-transition: height .3s, -webkit-transform .3s;
  transition: height .3s, transform .3s
}

.project-data {
  border-top: 1px dotted $gray-300;
  /* width: 100% */
}

.project-data>.row {
  margin-top: 1rem;
}

.project-data .row {
  margin-bottom: 1.5rem;
}

.container .project-data {
  margin: 0;
  padding: 0;
}

.project-data-close {
  background-size: 100% 100%;
  display: block;
  height: 23px;
  width: 25px
}

.project-data-close svg {
  height: 100%;
  color: $body-color;
}

.project-data-close svg * {
  -moz-transition: fill .3s;
  -o-transition: fill .3s;
  -webkit-transition: fill .3s;
  transition: fill .3s
}

.align-right {
  text-align: right;
}

.project {
  border: none;
}

.project-data h4 {
  font-size: 0.8rem;
  color: #d1d2d5;
}

/* Extra small devices (tablets, <576px, max container width: none) */
@media (max-width: 576px) {
  .projects--has-selection {
    padding-bottom: 40px
  }

  .symbol {
    font: 400 3rem/4rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  .project {
    height: 6rem;
  }

  .project .title {
    font: 400 .9rem/1.1rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .project-data {
    font: 400 .9rem/1.1rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%
  }
}

/* Small devices (mobiles, >=576px and up, max container width: 540px) */
@media (min-width: 576px) {
  .projects--has-selection {
    padding-bottom: 40px
  }

  .symbol {
    font: 400 3rem/4rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  .project {
    height: 6rem;
  }

  .project .title {
    font: 400 .9rem/1.1rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .project-data {
    font: 400 .9rem/1.1rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%
  }
}

/* Medium devices (tablets, >=768px and up, max container width: 720) */
@media (min-width: 768px) {
  .projects--has-selection {
    padding-bottom: 80px
  }

  .symbol {
    font: 400 4rem/5rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  .project {
    height: 8rem;
  }

  .project .title {
    font: 400 1rem/1.2rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

/* Large devices (desktops, >=992px and up, max container width: 960px) */
@media (min-width: 992px) {
  .projects--has-selection {
    padding-bottom: 120px
  }

  .symbol {
    font: 400 4rem/5rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }
}

/* Extra large devices (large desktops, >=1200px and up, max container width: 1140px) */
@media (min-width: 1200px) {
  .projects--has-selection {
    padding-bottom: 180px
  }

  .project {
    clear: none;
    cursor: pointer;
    float: left;
    height: 10rem;
    padding: 28px 0 0;
    text-align: inherit;
    text-decoration: none;
    -moz-transition: color .3s;
    -o-transition: color .3s;
    -webkit-transition: color .3s;
    transition: color .3s;
  }

  .symbol {
    font: 400 5.5rem/5rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  .project .title {
    font: 400 1rem/1.2rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .project-data {
    font: 400 1rem/1.2rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%
  }
}