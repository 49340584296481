// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

@import './assets/sass/fonts';
@import './assets/sass/custom';

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";


/**********************************************************************
 * Defaults for XS-small devices.
 *
 * Rationale: No media query for 'xs' since this is the default in
 * Bootstrap.  Devices smaller than 576px (e.g. iPhone <= 11/12) will
 * fall back to these definitions.
 **********************************************************************/
h1 {
  font: 400 2.4rem/2.4rem "Calibre Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  left: 0;
  letter-spacing: 0;
  position: relative;
  margin-bottom: 36px;
  height: $section-header-height-sm;
}

h2 {
  font: 1.4rem/1.4rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  margin-top: 24px;
}

h3 {
  font: 800 1.1rem/2.2rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  margin-top: 24px;
}

a:link {
  text-decoration: none;
}

div.container {
  max-width: 1200px;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.brand-color {
  color: $primary;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  h1 {
    height: $section-header-height-sm;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  h1 {
    height: $section-header-height-md;
  }  
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  h1 {
    height: $section-header-height-lg;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  /**/
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  /**/
}
