@import '../../../assets/sass/custom';

.sticky-header {
  background-color: $body-bg;
}

.sticky-header.sticky-top {
  z-index: 90;
  top: $section-header-height-sm-full
}

.boolean.item span {
  background: transparent;
  border: 1px solid $gray-500;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.boolean.item span.selected {
  background: $gray-500;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .sticky-header.sticky-top {
    top: $section-header-height-sm-full
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .sticky-header.sticky-top {
    top: $section-header-height-md-full;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .sticky-header.sticky-top {
    top: $section-header-height-lg;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .sticky-header.sticky-top {
    top: $section-header-height-xl;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  
}