@import '../../../assets/sass/custom';

#welcome-container .large-on-load {
  min-height: 5000px;
}

.statement {
  font-family: 'Roboto Slab', serif;
  font-size: 1.35rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 2.5rem;
}

.subtext {
  font-family: 'Calibre Regular', Helvetica, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 2rem;
  padding-top: 48px;
}

.highlight {
  color: #898989;
  background-color: #e7e7e7;
  padding: 0 5px;
  border-radius: 4px;
}