@font-face {
  font-family: 'Calibre Bold';
  src: url('./fonts/calibre-bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-bold.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-bold.svg#59fa510f1be641e20d8483b192b568fe') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Calibre Bold';
  src: url('./fonts/calibre-bolditalic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-bolditalic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-bolditalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-bolditalic.svg#b651317436506cf961cb6e3c14a867f5') format('svg'); /* Legacy iOS */

  font-style:   italic;
  font-weight:  700;
}

@font-face {
  font-family: 'Calibre Light';
  src: url('./fonts/calibre-light.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-light.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-light.svg#33faf11a8aaac9860f1dbbb05f2cf0d7') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'Calibre Light';
  src: url('./fonts/calibre-lightitalic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-lightitalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-lightitalic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-lightitalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-lightitalic.svg#9a28014018acf2116aa9ab87a11da5da') format('svg'); /* Legacy iOS */

  font-style:   italic;
  font-weight:  200;
}

@font-face {
  font-family: 'Calibre Regular';
  src: url('./fonts/calibre-regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-regular.svg#7c0f30c7c076dfec109a0a95317bb4cc') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Calibre Regular';
  src: url('./fonts/calibre-regularitalic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/calibre-regularitalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/calibre-regularitalic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/calibre-regularitalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/calibre-regularitalic.svg#ce9ea6dffc72d0b0b1e1e6cd6da8dcae') format('svg'); /* Legacy iOS */

  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/RobotoSlab-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/RobotoSlab-Bold.woff') format('woff'), /* Modern Browsers */
       url('./fonts/RobotoSlab-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/RobotoSlab-Bold.svg#1e7d30aef2cafa73684937c25b057e0e') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/RobotoSlab-Light.eot'); /* IE9 Compat Modes */
  src: url('./fonts/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/RobotoSlab-Light.woff') format('woff'), /* Modern Browsers */
       url('./fonts/RobotoSlab-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/RobotoSlab-Light.svg#a9b6c0324b621b5aa630c2aa3886eea7') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/RobotoSlab-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/RobotoSlab-Regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/RobotoSlab-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/RobotoSlab-Regular.svg#60c8d110cc4c187bf935fd36c91cecda') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/RobotoSlab-Thin.eot'); /* IE9 Compat Modes */
  src: url('./fonts/RobotoSlab-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/RobotoSlab-Thin.woff') format('woff'), /* Modern Browsers */
       url('./fonts/RobotoSlab-Thin.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/RobotoSlab-Thin.svg#4aed4ca534b5d2d1972aff9faf389dfb') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  200;
}
