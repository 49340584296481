@import '../../assets/sass/custom';

#header-container {
  background-color: $body-bg;
  z-index: 100;
}

#logo-type {
    color: $body-color;
    font: 400 3rem/1.5rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-decoration: none;
}

#logo-type:hover {
    color: $gray-800;
}

#nav-popup {
  background-color: $body-bg;
  opacity: 100%;
}

#nav-popup .modal-header, #nav-popup .modal-body, #nav-popup .list-group-item  {
  background-color:  $body-bg;
}

#nav-popup .list-group-item {
  font: 400 1.5rem/1rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
  padding: 32px 0 1.5rem 0
}

#nav-popup .list-group-item.active {
  background-color: $primary;
}

#nav-popup .modal-header {
  border: none;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header-container {
    height: $section-header-height-sm;
  }

  #nav-popup .list-group-item {
    font: 400 1.5rem/1rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
    padding: 32px 0 1.5rem 0
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #header-container {
    height: $section-header-height-md;
  }

  #nav-popup .list-group-item {
    font: 400 2rem/1.2rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
    padding: 24px 0 1rem 0
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  #header-container {
    height: $section-header-height-lg;
  }

  #nav-popup .list-group-item {
    font: 400 3rem/1.5rem Calibre Light,Helvetica Neue,Helvetica,Arial,sans-serif;
    padding: 32px 0 1.5rem 0
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  
}