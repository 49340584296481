@import '../../../assets/sass/custom';

.fine-print {
  position: relative;
  bottom: 0;
  opacity: 1;
  color: $gray-500;
  background-color: $body-bg;
  display: block;
  margin: 32px 0 0 0;
  font-size: 0.75rem;
  width: 100%;
}
