$font-family-sans-serif: "Calibre Light", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      "Roboto Slab", Georgia, "Times New Roman", Times, serif !default;

$gray-100: #f2f2f2 !default;                // background color
$gray-300: #D3D2D5 !default;
$gray-500: #a1a2a5 !default;
$gray-800: #303135 !default;

$primary:             #EB2344 !default;     // link color
$success:             #2DCC70 !default;
$info:                #C6C5DA !default;
$warning:             #EEE7FF !default;
$danger:              #26FFFA !default;
$inverse:             #605C66 !default;     // text color

$body-bg:       $gray-100 !default;
$body-color:    $inverse !default;
$inverse-bg:    $inverse !default;
$inverse-color: $gray-300 !default;

$border-radius:               0rem !default;
$border-radius-lg:            0rem !default;
$border-radius-sm:            0rem !default;

$section-header-height:       200px;    // default
$section-header-height-sm:    100px;
$section-header-height-md:    100px;
$section-header-height-lg:    200px;
$section-header-height-xl:    200px;

$section-header-height-sm-full:    136px;
$section-header-height-md-full:    100px;

$modal-fade-transform: scale(.8)